import { faker } from '@faker-js/faker';
import { cleanup, fireEvent, render } from '@testing-library/react';
import * as React from 'react';
import { Provider } from 'react-redux';

import redux from '../../redux';
import translations from '../../translations';
import BannerManager from '../banner-manager';

translations.init();

function TestComponent() {
  return (
    <Provider store={redux.store}>
      <BannerManager machineName={faker.word.words()} />
    </Provider>
  );
}

describe('banner manager', () => {
  afterEach(() => {
    cleanup();
  });

  test('should render the banner, then close the banner', () => {
    // render the component
    render(
      <>
        <div className="alert-box-wrapper" />
        <TestComponent />
      </>,
    );

    // get the banner
    const bannerElements = document.getElementsByClassName('alert-box');
    const banner = bannerElements[0];

    // verify banner to be in the document
    expect(bannerElements.length).toBe(1);

    // get the close button
    const closeElements = document.getElementsByClassName('close');

    // emulate user action
    fireEvent.click(closeElements[0]);

    // verify that the banner has been closed
    expect(banner.children[0].textContent).toStrictEqual('');
  });

  test('non-existent wrapper', () => {
    // render the component
    render(<TestComponent />);

    // get the banner
    const bannerElements = document.getElementsByClassName('alert-box');

    // verify banner not to be in the document
    expect(bannerElements.length).toBe(0);
  });
});
