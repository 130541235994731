import { connect, Provider } from 'react-redux';
import AlertTypeSelection from './AlertTypeSelection';
import store from './store';
import React from 'react';
import {
  setAlertType,
  setSensorTypes,
} from '../../reducers/alerts/alertRuleSlice';
// TODO We won't need this once Alert Rule Edit is fully converted into React
// use redux hooks in the components instead
const AlertTypeSelectionContainer = (props) => {
  return (
    <Provider store={store}>
      <ConnectedAlertTypeSelection {...props} />
    </Provider>
  );
};

const mapStateToProps = (state, { alertTypeOptions }) => {
  return {
    alertTypeOptions,
    selectedAlertType: state.alertRule.alertType,
  };
};

const mapDispatchToProps = (dispatch, { alertType }) => {
  // Use alertType passed from Rails to set initial value
  if (alertType) dispatch(setAlertType(alertType));
  // Listens to ajax call from 'updateDynamicInputs' method
  // in file 'alert_rules.es6'
  $(document).on('alertRuleFormLoaded', (event, alertRule) => {
    dispatch(setSensorTypes(alertRule.sensorTypes));
  });

  return {
    setAlertType: (value) => dispatch(setAlertType(value)),
  };
};

const ConnectedAlertTypeSelection = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertTypeSelection);

export default AlertTypeSelectionContainer;
