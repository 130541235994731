/*
File generated by js-routes 1.4.14
Based on Rails 7.0.8.3 routes of Dashboard::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// accept_user_invitation => /users/invitation/accept(.:format)
  // function(options)
  accept_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"accept",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// active_alerts => /active_alerts(.:format)
  // function(options)
  active_alerts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"active_alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_alert_logs => /admin/alert_logs(.:format)
  // function(options)
  admin_alert_logs_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"alert_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// alert_details => /alert_details(.:format)
  // function(options)
  alert_details_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// alert_event => /alert_events/:id(.:format)
  // function(id, options)
  alert_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// alert_events => /alert_events(.:format)
  // function(options)
  alert_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// alert_history_index => /alert_history(.:format)
  // function(options)
  alert_history_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// alert_log_delivery_info => /alert_logs/:alert_log_id/delivery_info(.:format)
  // function(alert_log_id, options)
  alert_log_delivery_info_path: Utils.route([["alert_log_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_logs",false],[2,[7,"/",false],[2,[3,"alert_log_id",false],[2,[7,"/",false],[2,[6,"delivery_info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// alert_report => /alert_reports/:id(.:format)
  // function(id, options)
  alert_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// alert_reports => /alert_reports(.:format)
  // function(options)
  alert_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// alert_rule => /alert_rules/:id(.:format)
  // function(id, options)
  alert_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// alert_rule_filters => /alert_rule_filters(.:format)
  // function(options)
  alert_rule_filters_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rule_filters",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// alert_rule_test_messages => /alert_rule_test_messages(.:format)
  // function(options)
  alert_rule_test_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rule_test_messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// alert_rules => /alert_rules(.:format)
  // function(options)
  alert_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// alert_rules_managed_machine => /managed_machines/:id/alert_rules(.:format)
  // function(id, options)
  alert_rules_managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"alert_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// alert_test_send_email => /alert_test/send_email(.:format)
  // function(options)
  alert_test_send_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_test",false],[2,[7,"/",false],[2,[6,"send_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// alert_test_send_sms => /alert_test/send_sms(.:format)
  // function(options)
  alert_test_send_sms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_test",false],[2,[7,"/",false],[2,[6,"send_sms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// alert_test_voice_call => /alert_test/voice_call(.:format)
  // function(options)
  alert_test_voice_call_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_test",false],[2,[7,"/",false],[2,[6,"voice_call",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_alert_rules => /api/alert_rules(.:format)
  // function(options)
  api_alert_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"alert_rules",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_customer_groups => /api/customer_groups(.:format)
  // function(options)
  api_customer_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_delivery_events_save_sms_status => /api/delivery_events/save_sms_status(.:format)
  // function(options)
  api_delivery_events_save_sms_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"delivery_events",false],[2,[7,"/",false],[2,[6,"save_sms_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_delivery_events_save_voice_call_status => /api/delivery_events/save_voice_call_status(.:format)
  // function(options)
  api_delivery_events_save_voice_call_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"delivery_events",false],[2,[7,"/",false],[2,[6,"save_voice_call_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_gateway_auth_key => /api/gateways/:gateway_serial_number/auth_key(.:format)
  // function(gateway_serial_number, options)
  api_gateway_auth_key_path: Utils.route([["gateway_serial_number",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[3,"gateway_serial_number",false],[2,[7,"/",false],[2,[6,"auth_key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_auth_is_action_allowed => /api/internal/v1/auth/is_action_allowed(.:format)
  // function(options)
  api_internal_v1_auth_is_action_allowed_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"is_action_allowed",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_internal_v1_customer_tags => /api/internal/v1/customer_tags(.:format)
  // function(options)
  api_internal_v1_customer_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"customer_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_em_asset => /api/internal/v1/em_assets/:id(.:format)
  // function(id, options)
  api_internal_v1_em_asset_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"em_assets",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_internal_v1_em_assets => /api/internal/v1/em_assets(.:format)
  // function(options)
  api_internal_v1_em_assets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"em_assets",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_em_assets_user => /api/internal/v1/em_assets_users/:id(.:format)
  // function(id, options)
  api_internal_v1_em_assets_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"em_assets_users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_internal_v1_machine_model_configuration => /api/internal/v1/machine_model_configuration(.:format)
  // function(options)
  api_internal_v1_machine_model_configuration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"machine_model_configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_managed_machines => /api/internal/v1/managed_machines(.:format)
  // function(options)
  api_internal_v1_managed_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_mm_customer_tags => /api/internal/v1/mm_customer_tags(.:format)
  // function(options)
  api_internal_v1_mm_customer_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"mm_customer_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_primary_investigators => /api/internal/v1/primary_investigators(.:format)
  // function(options)
  api_internal_v1_primary_investigators_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"primary_investigators",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_protocol => /api/internal/v1/protocols/:id(.:format)
  // function(id, options)
  api_internal_v1_protocol_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"protocols",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_internal_v1_protocols => /api/internal/v1/protocols(.:format)
  // function(options)
  api_internal_v1_protocols_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"protocols",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_registrations => /api/internal/v1/registrations(.:format)
  // function(options)
  api_internal_v1_registrations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"registrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_reports => /api/internal/v1/reports(.:format)
  // function(options)
  api_internal_v1_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_schedule_event => /api/internal/v1/schedule_events/:id(.:format)
  // function(id, options)
  api_internal_v1_schedule_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"schedule_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_internal_v1_schedule_events => /api/internal/v1/schedule_events(.:format)
  // function(options)
  api_internal_v1_schedule_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"schedule_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_send_mail => /api/internal/v1/send_mail(.:format)
  // function(options)
  api_internal_v1_send_mail_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"send_mail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_time_zones => /api/internal/v1/time_zones(.:format)
  // function(options)
  api_internal_v1_time_zones_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"time_zones",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_usage => /api/internal/v1/usage/:id(.:format)
  // function(id, options)
  api_internal_v1_usage_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"usage",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_internal_v1_usage_index => /api/internal/v1/usage(.:format)
  // function(options)
  api_internal_v1_usage_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_internal_v1_user => /api/internal/v1/users/:id(.:format)
  // function(id, options)
  api_internal_v1_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// api_internal_v1_users => /api/internal/v1/users(.:format)
  // function(options)
  api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_lookup_ignore_gateways => /api/lookup/ignore_gateways(.:format)
  // function(options)
  api_lookup_ignore_gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"lookup",false],[2,[7,"/",false],[2,[6,"ignore_gateways",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_lookup_machine => /api/lookup/machines/:mac_address(.:format)
  // function(mac_address, options)
  api_lookup_machine_path: Utils.route([["mac_address",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"lookup",false],[2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"mac_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_lookup_machine_model => /api/lookup/machine_models/:model(.:format)
  // function(model, options)
  api_lookup_machine_model_path: Utils.route([["model",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"lookup",false],[2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"model",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_lookup_machine_models => /api/lookup/machine_models(.:format)
  // function(options)
  api_lookup_machine_models_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"lookup",false],[2,[7,"/",false],[2,[6,"machine_models",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_lookup_machines => /api/lookup/machines(.:format)
  // function(options)
  api_lookup_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"lookup",false],[2,[7,"/",false],[2,[6,"machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_lookup_machines_ed1_config => /api/lookup/machines_ed1_config/:mac_address(.:format)
  // function(mac_address, options)
  api_lookup_machines_ed1_config_path: Utils.route([["mac_address",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"lookup",false],[2,[7,"/",false],[2,[6,"machines_ed1_config",false],[2,[7,"/",false],[2,[3,"mac_address",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_lookup_machines_metadata => /api/lookup/machines_metadata(.:format)
  // function(options)
  api_lookup_machines_metadata_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"lookup",false],[2,[7,"/",false],[2,[6,"machines_metadata",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_machine => /api/machines/:uuid(.:format)
  // function(uuid, options)
  api_machine_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_machine_alert_logs => /api/machines/:machine_uuid/alert_logs(.:format)
  // function(machine_uuid, options)
  api_machine_alert_logs_path: Utils.route([["machine_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"machine_uuid",false],[2,[7,"/",false],[2,[6,"alert_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_machine_machine_sample_stats => /api/machines/:machine_uuid/sample_stats(.:format)
  // function(machine_uuid, options)
  api_machine_machine_sample_stats_path: Utils.route([["machine_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"machine_uuid",false],[2,[7,"/",false],[2,[6,"sample_stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_machine_machine_samples => /api/machines/:machine_uuid/samples(.:format)
  // function(machine_uuid, options)
  api_machine_machine_samples_path: Utils.route([["machine_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"machine_uuid",false],[2,[7,"/",false],[2,[6,"samples",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_machines => /api/machines(.:format)
  // function(options)
  api_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_machines_usage_aggregated => /api/machines/usage/aggregated(.:format)
  // function(options)
  api_machines_usage_aggregated_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[6,"usage",false],[2,[7,"/",false],[2,[6,"aggregated",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_machines_usage_hourly => /api/machines/usage/hourly(.:format)
  // function(options)
  api_machines_usage_hourly_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[6,"usage",false],[2,[7,"/",false],[2,[6,"hourly",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_machines_usage_status => /api/machines/usage/status(.:format)
  // function(options)
  api_machines_usage_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[6,"usage",false],[2,[7,"/",false],[2,[6,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_mobile_base => /mobile
  // function(options)
  api_mobile_base_path: Utils.route([], {}, [2,[7,"/",false],[6,"mobile",false]]),
// api_release_notes => /api/release_notes(.:format)
  // function(options)
  api_release_notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"release_notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_status_check => /api/status/check(.:format)
  // function(options)
  api_status_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"status",false],[2,[7,"/",false],[2,[6,"check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_user_activities => /api/user_activities(.:format)
  // function(options)
  api_user_activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"user_activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// api_users => /api/users(.:format)
  // function(options)
  api_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// assign_customer_groups_managed_machine => /managed_machines/:id/assign_customer_groups(.:format)
  // function(id, options)
  assign_customer_groups_managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"assign_customer_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// assign_to_user_alert_rules => /alert_rules/assign_to_user(.:format)
  // function(options)
  assign_to_user_alert_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[6,"assign_to_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// attachinary => /attachinary
  // function(options)
  attachinary_path: Utils.route([], {}, [2,[7,"/",false],[6,"attachinary",false]]),
// attachinary.cors => /attachinary/cors(.:format)
  // function(options)
  attachinary_cors_path: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"attachinary",false]],[7,"/",false]],[2,[6,"cors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// become_api_internal_v1_user => /api/internal/v1/users/:id/become(.:format)
  // function(id, options)
  become_api_internal_v1_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"become",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// bulk_action_modal_user_groups_users => /user_groups_users/bulk_action_modal(.:format)
  // function(options)
  bulk_action_modal_user_groups_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_groups_users",false],[2,[7,"/",false],[2,[6,"bulk_action_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// calendar_index => /calendar(.:format)
  // function(options)
  calendar_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calendar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// calibration => /calibration/:uuid(.:format)
  // function(uuid, options)
  calibration_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"calibration",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// calibration_adjust_calibration => /calibration/:calibration_uuid/adjust_calibration(.:format)
  // function(calibration_uuid, options)
  calibration_adjust_calibration_path: Utils.route([["calibration_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"calibration",false],[2,[7,"/",false],[2,[3,"calibration_uuid",false],[2,[7,"/",false],[2,[6,"adjust_calibration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// calibration_index => /calibration(.:format)
  // function(options)
  calibration_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calibration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// calibration_readings => /calibration/:calibration_uuid/readings(.:format)
  // function(calibration_uuid, options)
  calibration_readings_path: Utils.route([["calibration_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"calibration",false],[2,[7,"/",false],[2,[3,"calibration_uuid",false],[2,[7,"/",false],[2,[6,"readings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// calibration_verify_calibration => /calibration/:calibration_uuid/verify_calibration(.:format)
  // function(calibration_uuid, options)
  calibration_verify_calibration_path: Utils.route([["calibration_uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"calibration",false],[2,[7,"/",false],[2,[3,"calibration_uuid",false],[2,[7,"/",false],[2,[6,"verify_calibration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_edit_alert_report => /alert_reports/:id/cancel_edit(.:format)
  // function(id, options)
  cancel_edit_alert_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel_edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// change_logs_alert_rule => /alert_rules/:id/change_logs(.:format)
  // function(id, options)
  change_logs_alert_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"change_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// change_password => /users/change_password(.:format)
  // function(options)
  change_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"change_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// chart => /charts/:id(.:format)
  // function(id, options)
  chart_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// charts => /charts(.:format)
  // function(options)
  charts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// claim_alert_event => /alert_events/:id/claim(.:format)
  // function(id, options)
  claim_alert_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"claim",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// comment_alert_event => /alert_events/:id/comment(.:format)
  // function(id, options)
  comment_alert_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// comments_charts => /charts/comments(.:format)
  // function(options)
  comments_charts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// configuration_machine_model => /machine_models/:id/configuration(.:format)
  // function(id, options)
  configuration_machine_model_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// configuration_machine_models => /machine_models/configuration(.:format)
  // function(options)
  configuration_machine_models_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[6,"configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// configurations_machine_models => /machine_models/configurations(.:format)
  // function(options)
  configurations_machine_models_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[6,"configurations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// confirmation_api_internal_v1_users => /api/internal/v1/users/confirmation(.:format)
  // function(options)
  confirmation_api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// create_comment_alert_events => /alert_events/create_comment(.:format)
  // function(options)
  create_comment_alert_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[6,"create_comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// create_comment_charts => /charts/create_comment(.:format)
  // function(options)
  create_comment_charts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[6,"create_comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer => /customers/:id(.:format)
  // function(id, options)
  customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_add_users => /customers/:customer_id/add_users(.:format)
  // function(customer_id, options)
  customer_add_users_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"add_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_assign_customer_groups_to_machines => /customers/:customer_id/assign_customer_groups_to_machines(.:format)
  // function(customer_id, options)
  customer_assign_customer_groups_to_machines_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"assign_customer_groups_to_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_group => /customer_groups/:id(.:format)
  // function(id, options)
  customer_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_group_add_managed_machines => /customer_groups/:customer_group_id/add_managed_machines(.:format)
  // function(customer_group_id, options)
  customer_group_add_managed_machines_path: Utils.route([["customer_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"customer_group_id",false],[2,[7,"/",false],[2,[6,"add_managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_group_add_users => /customer_groups/:customer_group_id/add_users(.:format)
  // function(customer_group_id, options)
  customer_group_add_users_path: Utils.route([["customer_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"customer_group_id",false],[2,[7,"/",false],[2,[6,"add_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_group_load_paginated_collection => /customer_groups/:customer_group_id/load_paginated_collection(.:format)
  // function(customer_group_id, options)
  customer_group_load_paginated_collection_path: Utils.route([["customer_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"customer_group_id",false],[2,[7,"/",false],[2,[6,"load_paginated_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_group_load_tab_section => /customer_groups/:customer_group_id/load_tab_section(.:format)
  // function(customer_group_id, options)
  customer_group_load_tab_section_path: Utils.route([["customer_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"customer_group_id",false],[2,[7,"/",false],[2,[6,"load_tab_section",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_group_remove_managed_machine => /customer_groups/:customer_group_id/remove_managed_machine(.:format)
  // function(customer_group_id, options)
  customer_group_remove_managed_machine_path: Utils.route([["customer_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"customer_group_id",false],[2,[7,"/",false],[2,[6,"remove_managed_machine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_group_remove_managed_machines => /customer_groups/:customer_group_id/remove_managed_machines(.:format)
  // function(customer_group_id, options)
  customer_group_remove_managed_machines_path: Utils.route([["customer_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"customer_group_id",false],[2,[7,"/",false],[2,[6,"remove_managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_group_remove_user => /customer_groups/:customer_group_id/remove_user(.:format)
  // function(customer_group_id, options)
  customer_group_remove_user_path: Utils.route([["customer_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"customer_group_id",false],[2,[7,"/",false],[2,[6,"remove_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_group_tag_machines_in_balena => /customer_groups/:customer_group_id/tag_machines_in_balena(.:format)
  // function(customer_group_id, options)
  customer_group_tag_machines_in_balena_path: Utils.route([["customer_group_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"customer_group_id",false],[2,[7,"/",false],[2,[6,"tag_machines_in_balena",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_groups => /customer_groups(.:format)
  // function(options)
  customer_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// customer_list => /customer_list(.:format)
  // function(options)
  customer_list_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// customer_load_customer_groups_for_customer => /customers/:customer_id/load_customer_groups_for_customer(.:format)
  // function(customer_id, options)
  customer_load_customer_groups_for_customer_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"load_customer_groups_for_customer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_load_paginated_collection => /customers/:customer_id/load_paginated_collection(.:format)
  // function(customer_id, options)
  customer_load_paginated_collection_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"load_paginated_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_load_tab_section => /customers/:customer_id/load_tab_section(.:format)
  // function(customer_id, options)
  customer_load_tab_section_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"load_tab_section",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_remove_managed_machine => /customers/:customer_id/remove_managed_machine(.:format)
  // function(customer_id, options)
  customer_remove_managed_machine_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"remove_managed_machine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_remove_managed_machines => /customers/:customer_id/remove_managed_machines(.:format)
  // function(customer_id, options)
  customer_remove_managed_machines_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"remove_managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_tag => /customer_tags/:id(.:format)
  // function(id, options)
  customer_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// customer_tag_add_tag_to_managed_machines => /customer_tags/:customer_tag_id/add_tag_to_managed_machines(.:format)
  // function(customer_tag_id, options)
  customer_tag_add_tag_to_managed_machines_path: Utils.route([["customer_tag_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_tags",false],[2,[7,"/",false],[2,[3,"customer_tag_id",false],[2,[7,"/",false],[2,[6,"add_tag_to_managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_tag_machines_in_balena => /customers/:customer_id/tag_machines_in_balena(.:format)
  // function(customer_id, options)
  customer_tag_machines_in_balena_path: Utils.route([["customer_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"customer_id",false],[2,[7,"/",false],[2,[6,"tag_machines_in_balena",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_tag_managed_machines => /customer_tags/:customer_tag_id/managed_machines(.:format)
  // function(customer_tag_id, options)
  customer_tag_managed_machines_path: Utils.route([["customer_tag_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_tags",false],[2,[7,"/",false],[2,[3,"customer_tag_id",false],[2,[7,"/",false],[2,[6,"managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_tag_populate_add_machines_modal => /customer_tags/:customer_tag_id/populate_add_machines_modal(.:format)
  // function(customer_tag_id, options)
  customer_tag_populate_add_machines_modal_path: Utils.route([["customer_tag_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_tags",false],[2,[7,"/",false],[2,[3,"customer_tag_id",false],[2,[7,"/",false],[2,[6,"populate_add_machines_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_tag_populate_remove_machines_modal => /customer_tags/:customer_tag_id/populate_remove_machines_modal(.:format)
  // function(customer_tag_id, options)
  customer_tag_populate_remove_machines_modal_path: Utils.route([["customer_tag_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_tags",false],[2,[7,"/",false],[2,[3,"customer_tag_id",false],[2,[7,"/",false],[2,[6,"populate_remove_machines_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_tag_remove_tag_for_managed_machines => /customer_tags/:customer_tag_id/remove_tag_for_managed_machines(.:format)
  // function(customer_tag_id, options)
  customer_tag_remove_tag_for_managed_machines_path: Utils.route([["customer_tag_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_tags",false],[2,[7,"/",false],[2,[3,"customer_tag_id",false],[2,[7,"/",false],[2,[6,"remove_tag_for_managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customer_tags => /customer_tags(.:format)
  // function(options)
  customer_tags_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// customers => /customers(.:format)
  // function(options)
  customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// customers_partner => /partners/:id/customers(.:format)
  // function(id, options)
  customers_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"customers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// customize_machine_view_user_view_settings => /user_view_settings/customize_machine_view(.:format)
  // function(options)
  customize_machine_view_user_view_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_view_settings",false],[2,[7,"/",false],[2,[6,"customize_machine_view",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// data_history_report => /data_history_reports/:id(.:format)
  // function(id, options)
  data_history_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// data_history_reports => /data_history_reports(.:format)
  // function(options)
  data_history_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_comment_chart => /charts/:id/destroy_comment(.:format)
  // function(id, options)
  destroy_comment_chart_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"destroy_comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// distribution_map_managed_machines => /managed_machines/distribution_map(.:format)
  // function(options)
  distribution_map_managed_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[6,"distribution_map",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// duplicate_alert_rule => /alert_rules/:id/duplicate(.:format)
  // function(id, options)
  duplicate_alert_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"duplicate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_alert_event => /alert_events/:id/edit(.:format)
  // function(id, options)
  edit_alert_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_alert_report => /alert_reports/:id/edit(.:format)
  // function(id, options)
  edit_alert_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_alert_rule => /alert_rules/:id/edit(.:format)
  // function(id, options)
  edit_alert_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_api_internal_v1_usage => /api/internal/v1/usage/:id/edit(.:format)
  // function(id, options)
  edit_api_internal_v1_usage_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"usage",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_chart => /charts/:id/edit(.:format)
  // function(id, options)
  edit_chart_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_comment_chart => /charts/:id/edit_comment(.:format)
  // function(id, options)
  edit_comment_chart_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_configuration_machine_models => /machine_models/edit_configuration(.:format)
  // function(options)
  edit_configuration_machine_models_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[6,"edit_configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_customer => /customers/:id/edit(.:format)
  // function(id, options)
  edit_customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_customer_group => /customer_groups/:id/edit(.:format)
  // function(id, options)
  edit_customer_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_customer_tag => /customer_tags/:id/edit(.:format)
  // function(id, options)
  edit_customer_tag_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_data_history_report => /data_history_reports/:id/edit(.:format)
  // function(id, options)
  edit_data_history_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_gateway => /gateways/:id/edit(.:format)
  // function(id, options)
  edit_gateway_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_machine => /machines/:id/edit(.:format)
  // function(id, options)
  edit_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_machine_model => /machine_models/:id/edit(.:format)
  // function(id, options)
  edit_machine_model_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_machine_model_sensor => /machine_models/:machine_model_id/sensors/:id/edit(.:format)
  // function(machine_model_id, id, options)
  edit_machine_model_sensor_path: Utils.route([["machine_model_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"machine_model_id",false],[2,[7,"/",false],[2,[6,"sensors",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// edit_managed_machine => /managed_machines/:id/edit(.:format)
  // function(id, options)
  edit_managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_oauth_application => /oauth/applications/:id/edit(.:format)
  // function(id, options)
  edit_oauth_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_partner => /partners/:id/edit(.:format)
  // function(id, options)
  edit_partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_partner_machine_machine => /machines/:id/edit_partner_machine(.:format)
  // function(id, options)
  edit_partner_machine_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit_partner_machine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
  // function(id, options)
  edit_rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// edit_user => /users/:id/edit(.:format)
  // function(id, options)
  edit_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_group => /user_groups/:id/edit(.:format)
  // function(id, options)
  edit_user_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// emails => /emails(.:format)
  // function(options)
  emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// equipment_usage_timeline_utilizations => /utilizations/equipment_usage_timeline(.:format)
  // function(options)
  equipment_usage_timeline_utilizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"utilizations",false],[2,[7,"/",false],[2,[6,"equipment_usage_timeline",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// equipment_usage_utilizations => /utilizations/equipment_usage(.:format)
  // function(options)
  equipment_usage_utilizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"utilizations",false],[2,[7,"/",false],[2,[6,"equipment_usage",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// equipment_utilization_samples_utilizations => /utilizations/equipment_utilization_samples(.:format)
  // function(options)
  equipment_utilization_samples_utilizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"utilizations",false],[2,[7,"/",false],[2,[6,"equipment_utilization_samples",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// event_history_alert_event => /alert_events/:id/event_history(.:format)
  // function(id, options)
  event_history_alert_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"event_history",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// event_list_alert_events => /alert_events/event_list(.:format)
  // function(options)
  event_list_alert_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[6,"event_list",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// feature_flags => /feature_flags
  // function(options)
  feature_flags_path: Utils.route([], {}, [2,[7,"/",false],[6,"feature_flags",false]]),
// find_machines => /machines/find(.:format)
  // function(options)
  find_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[6,"find",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// form_alert_rules => /alert_rules/form(.:format)
  // function(options)
  form_alert_rules_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[6,"form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// freshdesk_index => /freshdesk(.:format)
  // function(options)
  freshdesk_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"freshdesk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// gateway => /gateways/:id(.:format)
  // function(id, options)
  gateway_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// gateways => /gateways(.:format)
  // function(options)
  gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// generate_graph_data_history_reports => /data_history_reports/generate_graph(.:format)
  // function(options)
  generate_graph_data_history_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[2,[7,"/",false],[2,[6,"generate_graph",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// get_current_customer_group_customer_groups => /customer_groups/get_current_customer_group(.:format)
  // function(options)
  get_current_customer_group_customer_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[6,"get_current_customer_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// grape_swagger_rails => /mobile
  // function(options)
  grape_swagger_rails_path: Utils.route([], {}, [2,[7,"/",false],[6,"mobile",false]]),
// grape_swagger_rails.root => /mobile/
  // function(options)
  grape_swagger_rails_root_path: Utils.route([], {}, [2,[2,[7,"/",false],[6,"mobile",false]],[7,"/",false]]),
// groups_customer => /customers/:id/groups(.:format)
  // function(id, options)
  groups_customer_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// health_check => /health_check(.:format)
  // function(options)
  health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"health_check",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// help_url_api_internal_v1_users => /api/internal/v1/users/help_url(.:format)
  // function(options)
  help_url_api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"help_url",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// individual_machines_link_alert_reports => /alert_reports/individual_machines_link(.:format)
  // function(options)
  individual_machines_link_alert_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[2,[7,"/",false],[2,[6,"individual_machines_link",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// invitation_api_internal_v1_users => /api/internal/v1/users/invitation(.:format)
  // function(options)
  invitation_api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// list_modal_machines => /machines/list_modal(.:format)
  // function(options)
  list_modal_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[6,"list_modal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_accordion_data_calibration_index => /calibration/load_accordion_data(.:format)
  // function(options)
  load_accordion_data_calibration_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"calibration",false],[2,[7,"/",false],[2,[6,"load_accordion_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_accordion_data_managed_machines => /managed_machines/load_accordion_data(.:format)
  // function(options)
  load_accordion_data_managed_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[6,"load_accordion_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_data_user_activities => /user_activities/load_data(.:format)
  // function(options)
  load_data_user_activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_activities",false],[2,[7,"/",false],[2,[6,"load_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_drawer_collection_customer_groups => /customer_groups/load_drawer_collection(.:format)
  // function(options)
  load_drawer_collection_customer_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[6,"load_drawer_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_drawer_collection_customers => /customers/load_drawer_collection(.:format)
  // function(options)
  load_drawer_collection_customers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"load_drawer_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_drawer_collection_partners => /partners/load_drawer_collection(.:format)
  // function(options)
  load_drawer_collection_partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[6,"load_drawer_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_root_paginated_collection => /load_root_paginated_collection(.:format)
  // function(options)
  load_root_paginated_collection_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"load_root_paginated_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// load_root_tab_section => /load_root_tab_section(.:format)
  // function(options)
  load_root_tab_section_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"load_root_tab_section",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// load_selector_collection_customer_groups => /customer_groups/load_selector_collection(.:format)
  // function(options)
  load_selector_collection_customer_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[6,"load_selector_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_selector_collection_managed_machines => /managed_machines/load_selector_collection(.:format)
  // function(options)
  load_selector_collection_managed_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[6,"load_selector_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// load_unassigned_customer_groups_managed_machine => /managed_machines/:id/load_unassigned_customer_groups(.:format)
  // function(id, options)
  load_unassigned_customer_groups_managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"load_unassigned_customer_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// load_wifi_connection_status_collection_gateways => /gateways/load_wifi_connection_status_collection(.:format)
  // function(options)
  load_wifi_connection_status_collection_gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[6,"load_wifi_connection_status_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// machine => /machines/:id(.:format)
  // function(id, options)
  machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// machine_hourly_samples_utilizations => /utilizations/machine_hourly_samples(.:format)
  // function(options)
  machine_hourly_samples_utilizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"utilizations",false],[2,[7,"/",false],[2,[6,"machine_hourly_samples",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// machine_model => /machine_models/:id(.:format)
  // function(id, options)
  machine_model_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// machine_model_configurations_create => /machine_model_configurations/create(.:format)
  // function(options)
  machine_model_configurations_create_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_model_configurations",false],[2,[7,"/",false],[2,[6,"create",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// machine_model_configurations_destroy => /machine_model_configurations/destroy(.:format)
  // function(options)
  machine_model_configurations_destroy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_model_configurations",false],[2,[7,"/",false],[2,[6,"destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// machine_model_configurations_update => /machine_model_configurations/update(.:format)
  // function(options)
  machine_model_configurations_update_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_model_configurations",false],[2,[7,"/",false],[2,[6,"update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// machine_model_custom_branding => /machine_models/:machine_model_id/custom_branding(.:format)
  // function(machine_model_id, options)
  machine_model_custom_branding_path: Utils.route([["machine_model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"machine_model_id",false],[2,[7,"/",false],[2,[6,"custom_branding",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// machine_model_custom_branding_update => /machine_models/:machine_model_id/custom_branding_update(.:format)
  // function(machine_model_id, options)
  machine_model_custom_branding_update_path: Utils.route([["machine_model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"machine_model_id",false],[2,[7,"/",false],[2,[6,"custom_branding_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// machine_model_sensor => /machine_models/:machine_model_id/sensors/:id(.:format)
  // function(machine_model_id, id, options)
  machine_model_sensor_path: Utils.route([["machine_model_id",true],["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"machine_model_id",false],[2,[7,"/",false],[2,[6,"sensors",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// machine_model_sensor_population => /machine_models/:machine_model_id/sensor_population(.:format)
  // function(machine_model_id, options)
  machine_model_sensor_population_path: Utils.route([["machine_model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"machine_model_id",false],[2,[7,"/",false],[2,[6,"sensor_population",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// machine_model_sensor_removal => /machine_models/:machine_model_id/sensor_removal(.:format)
  // function(machine_model_id, options)
  machine_model_sensor_removal_path: Utils.route([["machine_model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"machine_model_id",false],[2,[7,"/",false],[2,[6,"sensor_removal",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// machine_model_sensors => /machine_models/:machine_model_id/sensors(.:format)
  // function(machine_model_id, options)
  machine_model_sensors_path: Utils.route([["machine_model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"machine_model_id",false],[2,[7,"/",false],[2,[6,"sensors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// machine_models => /machine_models(.:format)
  // function(options)
  machine_models_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// machine_utilization_samples_utilizations => /utilizations/machine_utilization_samples(.:format)
  // function(options)
  machine_utilization_samples_utilizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"utilizations",false],[2,[7,"/",false],[2,[6,"machine_utilization_samples",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// machines => /machines(.:format)
  // function(options)
  machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// machines_alert_rule => /alert_rules/:id/machines(.:format)
  // function(id, options)
  machines_alert_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// managed_machine => /managed_machines/:id(.:format)
  // function(id, options)
  managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// managed_machine_reload_details => /managed_machines/:managed_machine_id/reload_details(.:format)
  // function(managed_machine_id, options)
  managed_machine_reload_details_path: Utils.route([["managed_machine_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"managed_machine_id",false],[2,[7,"/",false],[2,[6,"reload_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// managed_machines => /managed_machines(.:format)
  // function(options)
  managed_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// me_api_internal_v1_users => /api/internal/v1/users/me(.:format)
  // function(options)
  me_api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"me",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// my_api_customer_groups => /api/customer_groups/my(.:format)
  // function(options)
  my_api_customer_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[6,"my",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// my_api_internal_v1_em_assets => /api/internal/v1/em_assets/my(.:format)
  // function(options)
  my_api_internal_v1_em_assets_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"em_assets",false],[2,[7,"/",false],[2,[6,"my",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// my_api_internal_v1_schedule_events => /api/internal/v1/schedule_events/my(.:format)
  // function(options)
  my_api_internal_v1_schedule_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"schedule_events",false],[2,[7,"/",false],[2,[6,"my",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// my_api_users => /api/users/my(.:format)
  // function(options)
  my_api_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"my",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// native_oauth_authorization => /oauth/authorize/native(.:format)
  // function(options)
  native_oauth_authorization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[2,[7,"/",false],[2,[6,"native",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_alert_event => /alert_events/new(.:format)
  // function(options)
  new_alert_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_alert_report => /alert_reports/new(.:format)
  // function(options)
  new_alert_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_alert_rule => /alert_rules/new(.:format)
  // function(options)
  new_alert_rule_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_alert_rule_test_message => /alert_rule_test_messages/new(.:format)
  // function(options)
  new_alert_rule_test_message_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rule_test_messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_api_internal_v1_usage => /api/internal/v1/usage/new(.:format)
  // function(options)
  new_api_internal_v1_usage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"usage",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_chart => /charts/new(.:format)
  // function(options)
  new_chart_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_comment_charts => /charts/new_comment(.:format)
  // function(options)
  new_comment_charts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[6,"new_comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_configuration_machine_models => /machine_models/new_configuration(.:format)
  // function(options)
  new_configuration_machine_models_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[6,"new_configuration",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_customer => /customers/new(.:format)
  // function(options)
  new_customer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_customer_group => /customer_groups/new(.:format)
  // function(options)
  new_customer_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_data_history_report => /data_history_reports/new(.:format)
  // function(options)
  new_data_history_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_gateway => /gateways/new(.:format)
  // function(options)
  new_gateway_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_machine => /machines/new(.:format)
  // function(options)
  new_machine_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_machine_model => /machine_models/new(.:format)
  // function(options)
  new_machine_model_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_machine_model_sensor => /machine_models/:machine_model_id/sensors/new(.:format)
  // function(machine_model_id, options)
  new_machine_model_sensor_path: Utils.route([["machine_model_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machine_models",false],[2,[7,"/",false],[2,[3,"machine_model_id",false],[2,[7,"/",false],[2,[6,"sensors",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_oauth_application => /oauth/applications/new(.:format)
  // function(options)
  new_oauth_application_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_partner => /partners/new(.:format)
  // function(options)
  new_partner_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// new_rails_conductor_inbound_email_source => /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
  // function(options)
  new_rails_conductor_inbound_email_source_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// new_report => /reports/new(.:format)
  // function(options)
  new_report_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user => /users/new(.:format)
  // function(options)
  new_user_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_group => /user_groups/new(.:format)
  // function(options)
  new_user_group_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_groups",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_invitation => /users/invitation/new(.:format)
  // function(options)
  new_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_application => /oauth/applications/:id(.:format)
  // function(id, options)
  oauth_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_applications => /oauth/applications(.:format)
  // function(options)
  oauth_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_authorization => /oauth/authorize(.:format)
  // function(options)
  oauth_authorization_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorize",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_authorized_application => /oauth/authorized_applications/:id(.:format)
  // function(id, options)
  oauth_authorized_application_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// oauth_authorized_applications => /oauth/authorized_applications(.:format)
  // function(options)
  oauth_authorized_applications_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"authorized_applications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_introspect => /oauth/introspect(.:format)
  // function(options)
  oauth_introspect_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"introspect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_revoke => /oauth/revoke(.:format)
  // function(options)
  oauth_revoke_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"revoke",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_token => /oauth/token(.:format)
  // function(options)
  oauth_token_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// oauth_token_info => /oauth/token/info(.:format)
  // function(options)
  oauth_token_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"oauth",false],[2,[7,"/",false],[2,[6,"token",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// open_door => /open_door(.:format)
  // function(options)
  open_door_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"open_door",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ops_alert_events => /ops/alert_events(.:format)
  // function(options)
  ops_alert_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ops",false],[2,[7,"/",false],[2,[6,"alert_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ops_bulk_add_machines => /ops/bulk_add_machines(.:format)
  // function(options)
  ops_bulk_add_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ops",false],[2,[7,"/",false],[2,[6,"bulk_add_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ops_bulk_destroy_machines => /ops/bulk_destroy_machines(.:format)
  // function(options)
  ops_bulk_destroy_machines_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ops",false],[2,[7,"/",false],[2,[6,"bulk_destroy_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ops_dashboard => /ops/dashboard(.:format)
  // function(options)
  ops_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ops",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ops_dashboard_load_accordion => /ops/dashboard_load_accordion(.:format)
  // function(options)
  ops_dashboard_load_accordion_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ops",false],[2,[7,"/",false],[2,[6,"dashboard_load_accordion",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ops_outages => /ops/outages(.:format)
  // function(options)
  ops_outages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ops",false],[2,[7,"/",false],[2,[6,"outages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ops_verify_serial_numbers => /ops/verify_serial_numbers(.:format)
  // function(options)
  ops_verify_serial_numbers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ops",false],[2,[7,"/",false],[2,[6,"verify_serial_numbers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ops_verify_serial_numbers_destroy => /ops/verify_serial_numbers_destroy(.:format)
  // function(options)
  ops_verify_serial_numbers_destroy_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"ops",false],[2,[7,"/",false],[2,[6,"verify_serial_numbers_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// partner => /partners/:id(.:format)
  // function(id, options)
  partner_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// partner_load_customers_for_partner => /partners/:partner_id/load_customers_for_partner(.:format)
  // function(partner_id, options)
  partner_load_customers_for_partner_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"load_customers_for_partner",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partner_load_paginated_collection => /partners/:partner_id/load_paginated_collection(.:format)
  // function(partner_id, options)
  partner_load_paginated_collection_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"load_paginated_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partner_load_tab_section => /partners/:partner_id/load_tab_section(.:format)
  // function(partner_id, options)
  partner_load_tab_section_path: Utils.route([["partner_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[2,[7,"/",false],[2,[3,"partner_id",false],[2,[7,"/",false],[2,[6,"load_tab_section",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// partners => /partners(.:format)
  // function(options)
  partners_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"partners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// password_expired => /users/password_expired(.:format)
  // function(options)
  password_expired_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password_expired",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// past_reports_data_reports => /reports/past_reports_data(.:format)
  // function(options)
  past_reports_data_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"past_reports_data",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pause_alerts_managed_machine => /managed_machines/:id/pause_alerts(.:format)
  // function(id, options)
  pause_alerts_managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"pause_alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// preferences => /users/preferences(.:format)
  // function(options)
  preferences_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"preferences",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_blob_representation => /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_blob_representation_proxy => /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_proxy_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]]]),
// rails_conductor_inbound_email => /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
  // function(id, options)
  rails_conductor_inbound_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_incinerate => /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_incinerate_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"incinerate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_reroute => /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
  // function(inbound_email_id, options)
  rails_conductor_inbound_email_reroute_path: Utils.route([["inbound_email_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[3,"inbound_email_id",false],[2,[7,"/",false],[2,[6,"reroute",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_email_sources => /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
  // function(options)
  rails_conductor_inbound_email_sources_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"sources",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_conductor_inbound_emails => /rails/conductor/action_mailbox/inbound_emails(.:format)
  // function(options)
  rails_conductor_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"conductor",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mailgun_inbound_emails => /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
  // function(options)
  rails_mailgun_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mailgun",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[2,[7,"/",false],[2,[6,"mime",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_mandrill_inbound_emails => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_mandrill_inbound_health_check => /rails/action_mailbox/mandrill/inbound_emails(.:format)
  // function(options)
  rails_mandrill_inbound_health_check_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"mandrill",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_postmark_inbound_emails => /rails/action_mailbox/postmark/inbound_emails(.:format)
  // function(options)
  rails_postmark_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"postmark",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_relay_inbound_emails => /rails/action_mailbox/relay/inbound_emails(.:format)
  // function(options)
  rails_relay_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"relay",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_sendgrid_inbound_emails => /rails/action_mailbox/sendgrid/inbound_emails(.:format)
  // function(options)
  rails_sendgrid_inbound_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"action_mailbox",false],[2,[7,"/",false],[2,[6,"sendgrid",false],[2,[7,"/",false],[2,[6,"inbound_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rails_service_blob => /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"redirect",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_service_blob_proxy => /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_proxy_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[6,"proxy",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// release_alert_event => /alert_events/:id/release(.:format)
  // function(id, options)
  release_alert_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"release",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// release_notes => /release_notes(.:format)
  // function(options)
  release_notes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"release_notes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// remove_user_invitation => /users/invitation/remove(.:format)
  // function(options)
  remove_user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[2,[7,"/",false],[2,[6,"remove",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// report => /reports/:id(.:format)
  // function(id, options)
  report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// reports => /reports(.:format)
  // function(options)
  reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// resend_invitation_api_internal_v1_user => /api/internal/v1/users/:id/resend_invitation(.:format)
  // function(id, options)
  resend_invitation_api_internal_v1_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"resend_invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// root_level => /root_level(.:format)
  // function(options)
  root_level_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"root_level",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// run_alert_report => /alert_reports/:id/run(.:format)
  // function(id, options)
  run_alert_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// run_data_history_report => /data_history_reports/:id/run(.:format)
  // function(id, options)
  run_data_history_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// run_data_history_reports => /data_history_reports/run(.:format)
  // function(options)
  run_data_history_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[2,[7,"/",false],[2,[6,"run",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// samples_charts => /charts/samples(.:format)
  // function(options)
  samples_charts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[6,"samples",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// samples_datatable_managed_machine => /managed_machines/:id/samples_datatable(.:format)
  // function(id, options)
  samples_datatable_managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"samples_datatable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// samples_managed_machine => /managed_machines/:id/samples(.:format)
  // function(id, options)
  samples_managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"samples",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// save_ondemand_alert_report => /alert_reports/:id/save_ondemand(.:format)
  // function(id, options)
  save_ondemand_alert_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"save_ondemand",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// save_ondemand_data_history_report => /data_history_reports/:id/save_ondemand(.:format)
  // function(id, options)
  save_ondemand_data_history_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"save_ondemand",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// set_current_customer_group_customer_group => /customer_groups/:id/set_current_customer_group(.:format)
  // function(id, options)
  set_current_customer_group_customer_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"set_current_customer_group",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// setup => /setup/:id(.:format)
  // function(id, options)
  setup_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// setup_troubleshoot => /setup/:setup_id/troubleshoot(.:format)
  // function(setup_id, options)
  setup_troubleshoot_path: Utils.route([["setup_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[3,"setup_id",false],[2,[7,"/",false],[2,[6,"troubleshoot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// sidekiq_web => /sidekiq
  // function(options)
  sidekiq_web_path: Utils.route([], {}, [2,[7,"/",false],[6,"sidekiq",false]]),
// sign_in => /sign_in(.:format)
  // function(options)
  sign_in_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sign_out_api_internal_v1_users => /api/internal/v1/users/sign_out(.:format)
  // function(options)
  sign_out_api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// sso_error => /sso/error(.:format)
  // function(options)
  sso_error_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sso",false],[2,[7,"/",false],[2,[6,"error",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sso_reset => /sso/reset(.:format)
  // function(options)
  sso_reset_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sso",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// start_sso => /sso/start/:slug(.:format)
  // function(slug, options)
  start_sso_path: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"sso",false],[2,[7,"/",false],[2,[6,"start",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// stats_charts => /charts/stats(.:format)
  // function(options)
  stats_charts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[6,"stats",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// subgroups_customer_group => /customer_groups/:id/subgroups(.:format)
  // function(id, options)
  subgroups_customer_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"customer_groups",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"subgroups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// switch_back_api_internal_v1_users => /api/internal/v1/users/switch_back(.:format)
  // function(options)
  switch_back_api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"switch_back",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// switch_back_user_api_internal_v1_users => /api/internal/v1/users/switch_back_user(.:format)
  // function(options)
  switch_back_user_api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"switch_back_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// time_zone_api_internal_v1_users => /api/internal/v1/users/time_zone(.:format)
  // function(options)
  time_zone_api_internal_v1_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"time_zone",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// token_api_internal_v1_usage_index => /api/internal/v1/usage/token(.:format)
  // function(options)
  token_api_internal_v1_usage_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"usage",false],[2,[7,"/",false],[2,[6,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// tracking_consent => /tracking_consent/:id(.:format)
  // function(id, options)
  tracking_consent_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"tracking_consent",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// troubleshoot_gateways => /gateways/troubleshoot(.:format)
  // function(options)
  troubleshoot_gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[6,"troubleshoot",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// unlock_api_internal_v1_user => /api/internal/v1/users/:id/unlock(.:format)
  // function(id, options)
  unlock_api_internal_v1_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"internal",false],[2,[7,"/",false],[2,[6,"v1",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// unpause_alerts_managed_machine => /managed_machines/:id/unpause_alerts(.:format)
  // function(id, options)
  unpause_alerts_managed_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"managed_machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unpause_alerts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// unsubscriptions => /unsubscriptions(.:format)
  // function(options)
  unsubscriptions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"unsubscriptions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_comment_chart => /charts/:id/update_comment(.:format)
  // function(id, options)
  update_comment_chart_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"charts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_comment",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_enabled_alert_rule => /alert_rules/:id/update_enabled(.:format)
  // function(id, options)
  update_enabled_alert_rule_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_rules",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_enabled",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_enabled_report => /reports/:id/update_enabled(.:format)
  // function(id, options)
  update_enabled_report_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_enabled",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_partner_machine_machine => /machines/:id/update_partner_machine(.:format)
  // function(id, options)
  update_partner_machine_machine_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"machines",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"update_partner_machine",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_report_summary_alert_reports => /alert_reports/update_report_summary(.:format)
  // function(options)
  update_report_summary_alert_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"alert_reports",false],[2,[7,"/",false],[2,[6,"update_report_summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_report_summary_data_history_reports => /data_history_reports/update_report_summary(.:format)
  // function(options)
  update_report_summary_data_history_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"data_history_reports",false],[2,[7,"/",false],[2,[6,"update_report_summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_report_summary_reports => /reports/update_report_summary(.:format)
  // function(options)
  update_report_summary_reports_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"reports",false],[2,[7,"/",false],[2,[6,"update_report_summary",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user => /users/:id(.:format)
  // function(id, options)
  user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_activate => /users/:user_id/activate(.:format)
  // function(user_id, options)
  user_activate_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"activate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_activities => /user_activities(.:format)
  // function(options)
  user_activities_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_activities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_asset_genius_omniauth_authorize => /users/auth/asset_genius(.:format)
  // function(options)
  user_asset_genius_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"asset_genius",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_asset_genius_omniauth_callback => /users/auth/asset_genius/callback(.:format)
  // function(options)
  user_asset_genius_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"asset_genius",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_become => /users/:user_id/become(.:format)
  // function(user_id, options)
  user_become_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"become",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_clear_and_become => /users/:user_id/clear_and_become(.:format)
  // function(user_id, options)
  user_clear_and_become_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"clear_and_become",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_group => /user_groups/:id(.:format)
  // function(id, options)
  user_group_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_groups",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_groups => /user_groups(.:format)
  // function(options)
  user_groups_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_groups",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_groups_user => /user_groups_users/:id(.:format)
  // function(id, options)
  user_groups_user_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_groups_users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_groups_users => /user_groups_users(.:format)
  // function(options)
  user_groups_users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"user_groups_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_invitation => /users/invitation(.:format)
  // function(options)
  user_invitation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"invitation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_lock => /users/:user_id/lock(.:format)
  // function(user_id, options)
  user_lock_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"lock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_oktaoauth_omniauth_authorize => /users/auth/oktaoauth(.:format)
  // function(options)
  user_oktaoauth_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"oktaoauth",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_oktaoauth_omniauth_callback => /users/auth/oktaoauth/callback(.:format)
  // function(options)
  user_oktaoauth_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"oktaoauth",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_saml_omniauth_authorize => /users/auth/saml(.:format)
  // function(options)
  user_saml_omniauth_authorize_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"saml",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_saml_omniauth_callback => /users/auth/saml/callback(.:format)
  // function(options)
  user_saml_omniauth_callback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"saml",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_unlock => /users/:user_id/unlock(.:format)
  // function(user_id, options)
  user_unlock_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_update_activity => /users/:user_id/update_activity(.:format)
  // function(user_id, options)
  user_update_activity_path: Utils.route([["user_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"user_id",false],[2,[7,"/",false],[2,[6,"update_activity",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_view_setting => /user_view_settings/:id(.:format)
  // function(id, options)
  user_view_setting_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"user_view_settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users => /users(.:format)
  // function(options)
  users_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// users_password => /users/password(.:format)
  // function(options)
  users_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// users_password_edit => /users/password/edit(.:format)
  // function(options)
  users_password_edit_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// users_sign_out => /users/sign_out(.:format)
  // function(options)
  users_sign_out_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// utilization => /utilizations/:uuid(.:format)
  // function(uuid, options)
  utilization_path: Utils.route([["uuid",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"utilizations",false],[2,[7,"/",false],[2,[3,"uuid",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// utilizations => /utilizations(.:format)
  // function(options)
  utilizations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"utilizations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// welcome_emails => /welcome_emails(.:format)
  // function(options)
  welcome_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"welcome_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// whitelist_managed_machines_gateway => /gateways/:id/whitelist_managed_machines(.:format)
  // function(id, options)
  whitelist_managed_machines_gateway_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"whitelist_managed_machines",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// wifi_connect_form_gateways => /gateways/wifi_connect_form(.:format)
  // function(options)
  wifi_connect_form_gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[6,"wifi_connect_form",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wifi_connect_gateways => /gateways/wifi_connect(.:format)
  // function(options)
  wifi_connect_gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[6,"wifi_connect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wifi_connect_setup_index => /setup/wifi_connect(.:format)
  // function(options)
  wifi_connect_setup_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup",false],[2,[7,"/",false],[2,[6,"wifi_connect",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wifi_edit_gateways => /gateways/wifi_edit(.:format)
  // function(options)
  wifi_edit_gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[6,"wifi_edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wifi_scan_gateways => /gateways/wifi_scan(.:format)
  // function(options)
  wifi_scan_gateways_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gateways",false],[2,[7,"/",false],[2,[6,"wifi_scan",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);

