import * as React from 'react';
import { Provider, connect } from 'react-redux';

import { selectCollapsed } from '../../reducers/navigation/navigationBarSlice';
import Logo from './Logo';
import store, { RootState } from './store';

interface OwnProps {
  collapsedLogoUrl: string;
  logoUrl: string;
}

const mapStateToProps = (
  state: RootState,
  { collapsedLogoUrl, logoUrl }: OwnProps,
) => ({
  collapsedLogoUrl,
  logoUrl,
  menuCollapsed: selectCollapsed(state),
});

const ConnectedLogo = connect(mapStateToProps, null)(Logo);

const LogoContainer = (props) => (
  <Provider store={store}>
    <ConnectedLogo {...props} />
  </Provider>
);

export default LogoContainer;
