import * as React from 'react';
import { Provider, connect } from 'react-redux';

import {
  selectCollapsed,
  setCollapsed,
} from '../../reducers/navigation/navigationBarSlice';
import CollapseMenuButton from './CollapseMenuButton';
import store, { AppDispatch, RootState } from './store';

interface OwnProps {
  showCollapseDrawer: boolean;
}

const mapStateToProps = (
  state: RootState,
  { showCollapseDrawer }: OwnProps,
) => ({
  collapsed: selectCollapsed(state),
  showCollapseDrawer,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setCollapsed: () => dispatch(setCollapsed()),
});

const ConnectedCollapseMenuButton = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CollapseMenuButton);

const CollapseMenuButtonContainer = (props) => (
  <Provider store={store}>
    <ConnectedCollapseMenuButton {...props} />
  </Provider>
);

export default CollapseMenuButtonContainer;
