import { createAsyncThunk } from '@reduxjs/toolkit';
import i18next from 'i18next';

const setContent = createAsyncThunk(
  'banner/set-content',
  async (data: string) => {
    const banner = document.getElementsByClassName('alert-box')[0];

    if (banner) {
      banner.children[0].textContent = data;

      const reportsLink = document.createElement('a');

      reportsLink.href = '/reports?tab=file-and-exports-section';
      reportsLink.textContent = i18next.t('reports-uppercase');
      banner.children[0].innerHTML = banner.children[0].innerHTML.replace(
        '{{reports}}',
        reportsLink.outerHTML,
      );
    }

    return data;
  },
);

export default setContent;
